.country-container{
    display: flex;
    justify-content: flex-end;
    align-items: right;
    margin-right: 10px;
}

.select-country {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    outline: none; /* Remove the default outline */
}

.select-country option {
    background-color: white; /* Change the background color of options */
    color: #333; /* Change the text color of options */
}

.select-country option:hover {
    background-color: transparent;
}
.carousel-outer .carousel .slide {
    background: none;
    margin-inline: 25px;
    align-self: center;
}
.carousel .slider-wrapper {
    height: 100% !important;
    padding: 10px;
}
.carousel.carousel-slider {
}

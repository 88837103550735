.nav-link {
    color: #3e5158;
    opacity: 0.7;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
}

.selected-link {
    color: #3c4a5b;
    font-weight: bolder;
    text-decoration: none;
}

.nav-link-active {
    color: #3c4a5b;
    text-decoration: none;
    font-weight: 600;
    border: 2px solid #f2aa00;
    border-radius: 24px;
    padding: 2px 18px 4px;
}

.navbar {
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    display: flex;
    z-index: 30;
    max-width: 1500px;
    width: 100%;
}

.icon-padding {
    padding-right: 5px;
}

.navbarBack {
    height: 80px;
    display: flex;
    justify-content: center;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    position: sticky;
}

.nav-link-cta {
    font-size: medium;
    padding: 6px 12px;
    background-color: #1a4bea;
    font-family: "Poppins", sans-serif;
    color: #fff;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    width: 20%;
    display: inline;
    cursor: pointer;
    margin: 0px 10px;
    text-decoration: none;
    font-weight: 600;
}

.nav-link-cta-white {
    font-size: medium;
    font-family: "Poppins", sans-serif;
    padding: 6px 12px;
    color: #000;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    width: 20%;
    display: inline;
    cursor: pointer;
    margin: 0px 10px;
    text-decoration: none;
    font-weight: 600;
}

.nav-link-cta-sec {
    font-size: medium;
    padding: 6px 12px;
    font-family: "Poppins", sans-serif;
    background-color: #ffc85c;
    color: #727d8a;
    border-radius: 8px;
    white-space: nowrap;
    text-align: center;
    width: 20%;
    display: inline;
    cursor: pointer;
    margin-right: 16px;
    text-decoration: none;
    font-weight: 600;
}

.text-bitcoin {
    padding-left: 5px;
    padding-right: 10px;
    color: #1a4bea;
    font-size: 1.2vmax;
    white-space: nowrap;
}

.button-icon {
    padding-right: 6px;
}

.navbar-logo {
    padding: 0px 20px;
    cursor: pointer;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(2, auto);
    list-style: none;
    text-align: center;
    justify-content: flex-end;
}

.nav-menu-center {
    display: grid;
    grid-template-columns: repeat(8, auto);
}

.nav-item {
    display: flex;
    align-items: center;
    height: 80px;
}
.nav-item-button {
    align-items: center;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background-color: #1888ff;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.container-wrapper {
    padding: 6px 50px !important;
}

@media screen and (max-width: 1140) and (min-width: 960px) {
    .text-bitcoin {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 960px) {
    .navbar {
        height: 8vh;
        z-index: 30;
    }

    .NavbarItems {
        position: relative;
    }

    .nav-link-cta-sec {
        background-color: unset;
        border: unset;
        color: #fff;
        text-align: center;
        margin-right: 0;
    }

    .nav-link-cta {
        background-color: unset;
        border: unset;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-inline-start: 0px;
        margin-block-start: 0px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 1;
        z-index: 30;
        transition: all 0.5s ease;
        position: fixed;
    }

    .nav-menu-center {
        display: none;
        padding-inline-start: 0px;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        padding: 0;
        justify-content: start;
        align-items: center;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-item {
        width: 50%;
    }

    .nav-links:hover {
        background-color: #1888ff;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        left: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        right: 20px;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1888ff;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background-color: #fff;
        color: #1888ff;
        transition: 250ms;
    }

    button {
        display: none;
    }
}

.linechart_path {
    stroke-width: 3;
    fill: none;
}

.linechart_axis {
    stroke: #bdc3c7;
}

.spinner {
    height: 200px;
    display: table !important;
    margin: auto;
}
